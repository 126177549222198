.list {
  .list-item {
    span {
      display: inline-block;
      margin-right: 10px;
      font-style: italic;

      &.success {
        color: green; }

      &.error {
        color: red; } } }

  .ant-list-item-content {
    flex: initial;
    width: 60%; } }
