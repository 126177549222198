.add-action {
  text-align: right;
  width: 100%; }

.list {
  .ant-badge-status-dot {
    transform: scale(1.5); } }

.add-purchase {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .type {
    width: 30%;

    span {
      font-size: 15px; }

    .ant-select {
      margin-top: 10px;
      width: 100%; } }

  .list-products {
    width: 100%;
    margin-top: 15px;

    .title {
      font-size: 15px;
      margin-bottom: 5px;

      .ant-input-search {
        margin-top: 10px; } }

    .content {
      padding: 0 10px;
      overflow-y: auto;
      max-height: 400px; }

    .footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end; } } }

.product-purchase {
  .product-purchase-content {
    max-height: 650px;
    overflow-y: auto; }


  .ant-list-item {
    .ant-list-item-meta-title {
      margin-bottom: 0; } } }
